





















import { omitBy, isNil } from 'lodash';
import CopyStakeStats from '@/views/CopyStake/CopyStakeStats/CopyStakeStats.vue';
import GameProviderCopyStakeStatsTable from '@/components/game-provider/GameProviderCopyStakeStatsTable.vue';
import {
  EStreamerTransactionType,
  GameProviderAccount,
  TStreamerStat,
  TStreamTransaction,
  TStreamTransactionsPayload
} from '@/api/schema';
import { defaultDates, TStreamFormDates } from '@/helpers/copyStakeHelpers';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import {
  downloadStreamCsvReportByGameProvider,
  getStreamTransactionsByGameProvider
} from '@/api/CopyStake';
import { doDownload } from '@/helpers/download';
import { getGameProviderAccount } from '@/api/GameProvider';

type TCopyStakeStatsState = {
  isLoading: boolean;
  isCsvLoading: boolean;
  gameProviderAccount: GameProviderAccount;
  dates: TStreamFormDates;
  copyStakeTransactionsParams: TStreamTransactionsPayload;
  copyStakeGGR: number;
  copyStakeStats: TStreamerStat[];
  copyStakeTransactions: TStreamTransaction[];
  copyStakeTransactionsHasNext: boolean;
};

export default {
  name: 'GameProviderCopyStakeStatsView',
  components: {
    CopyStakeStats,
    GameProviderCopyStakeStatsTable
  },
  data(): TCopyStakeStatsState {
    return {
      isLoading: true,
      isCsvLoading: false,
      gameProviderAccount: null,
      dates: { ...defaultDates },
      copyStakeGGR: 2200.26,
      copyStakeStats: [
        {
          copiedActionType: EStreamerTransactionType.BET_COPIED,
          copiedActionCount: 341,
          copiedActionAmountUsd: 2799.21
        },
        {
          copiedActionType: EStreamerTransactionType.WIN_COPIED,
          copiedActionCount: 295,
          copiedActionAmountUsd: 598.95
        },
        {
          copiedActionType: EStreamerTransactionType.ROLLBACK_BET_COPIED,
          copiedActionCount: 0,
          copiedActionAmountUsd: 0
        },
        {
          copiedActionType: EStreamerTransactionType.ROLLBACK_WIN_COPIED,
          copiedActionCount: 0,
          copiedActionAmountUsd: 0
        }
      ],
      copyStakeTransactionsParams: null,
      copyStakeTransactions: [],
      copyStakeTransactionsHasNext: false
    };
  },
  computed: {
    gameProviderId(): number | null {
      return this.$store.state.Auth.accountId;
    }
  },
  async created(): Promise<void> {
    const unwatchGameProviderId = this.$watch(
      'gameProviderId',
      async (payload?: number) => {
        if (!payload) {
          return;
        }

        await this.fetchGameProviderAccount();
        this.$watch(
          'dates',
          () => {
            // TODO: Will be soon.
            //this.fetchStakeStats();
          },
          {
            immediate: true,
            deep: true
          }
        );
        this.$watch(
          'copyStakeTransactionsParams',
          () => {
            // Hide table in DEV-6563
            // this.fetchStakeTransactions();
          },
          {
            deep: true
          }
        );
        this.copyStakeTransactionsParams = {
          size: 10,
          provider: this.gameProviderAccount.gameProviderName
        };
        unwatchGameProviderId();
      },
      {
        immediate: true
      }
    );
  },
  methods: {
    async fetchGameProviderAccount(): Promise<void> {
      try {
        this.gameProviderAccount = await getGameProviderAccount(
          this.gameProviderId
        );
      } catch (error) {
        errorToastMessage(error);
      }
    },
    handlerOnCopyStakeStatsTableFilterChange(
      payload: Record<string, any>
    ): void {
      this.copyStakeTransactionsParams = omitBy(
        {
          ...this.copyStakeTransactionsParams,
          ...payload
        },
        isNil
      );
    },
    handlerOnDateFilterChange(payload: TStreamFormDates): void {
      this.dates = payload;
    },
    async fetchStakeTransactions(): Promise<void> {
      try {
        this.isLoading = true;

        const { transactions, hasNext } =
          await getStreamTransactionsByGameProvider(
            this.copyStakeTransactionsParams
          );
        this.copyStakeTransactions = transactions;
        this.copyStakeTransactionsHasNext = hasNext;
      } catch (e) {
        errorToastMessage(e);
        this.copyStakeTransactions = [];
        this.copyStakeTransactionsHasNext = false;
      } finally {
        this.isLoading = false;
      }
    },
    // async fetchStakeStats(): Promise<void> {
    //   try {
    //     this.isLoading = true;
    //
    //     const { ggr, data }: TStreamerStats = await getStreamStats({
    //       ...this.dates,
    //       provider: this.gameProviderId
    //     });
    //     const isDataValid = Array.isArray(data) && data.length;
    //
    //     this.copyStakeGGR = ggr || 0;
    //     this.$set(this, 'copyStakeStats', isDataValid ? data : []);
    //
    //     if (!isDataValid) {
    //       this.$toast.error('There is no data for the specified parameters');
    //     }
    //   } catch (e) {
    //     errorToastMessage(e);
    //   } finally {
    //     this.isLoading = false;
    //   }
    // },
    async downloadCsv(): Promise<void> {
      try {
        this.isCsvLoading = true;

        const data = await downloadStreamCsvReportByGameProvider({
          ...this.copyStakeTransactionsParams,
          size: 10000
        });
        const url = window.URL.createObjectURL(new Blob([data]));
        const date = Object.values(this.dates)
          .map((item: string) => item.replaceAll('-', '.'))
          .join('-');
        doDownload(url, `copy-stake-report[${date}].csv`);
      } catch (e) {
        errorToastMessage(e);
      } finally {
        this.isCsvLoading = false;
      }
    }
  }
};
