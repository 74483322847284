





































































































































































import { PropType } from 'vue';
import { DataTableHeader } from 'vuetify';
import CopyStateTransactionTypeFilter from '@/components/copy-stake/CopyStateTransactionTypeFilter.vue';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';
import { capitalization, textOverflowMiddle } from '@/helpers/formatString';
import { copyToClipBoard } from '@/helpers/copyToClipBoard';
import { BASE_CURRENCY, VALUE_PLACEHOLDER } from '@/helpers/copyStakeHelpers';
import { activeOperators } from '@/helpers/operatorHelpers';
import formatDate from '@/helpers/formatDate';
import { EStreamerTransactionType, TStreamTransaction } from '@/api/schema';
import { MIN_DATE } from '@/helpers/gameProviderAdminHelper';

type TCopyStakeTableState = {
  MIN_DATE: string;
  tableHeaders: Readonly<DataTableHeader[]>;
  currentPaginationPage: number;
  previousPaginationPageDate: string[];
  activeOperators: Readonly<{ label: string; id: number }[]>;
  selectedOperatorId: number;
  selectedSearchKey: ESearchKey;
  selectedTransactionsType: EStreamerTransactionType;
  searchKeys: Readonly<{ text: string; value: ESearchKey }[]>;
  searchValue: string;
};

enum ESearchKey {
  USER_ID = 'operatorUserId',
  COPIED_FROM_WALLET_HASH = 'copiedFromWalletHash',
  GAME_NAME = 'gameName'
}

export default {
  name: 'GameProviderCopyStakeStatsTable',
  components: {
    CopyStateTransactionTypeFilter,
    VExtendedDataPicker
  },
  props: {
    dates: {
      type: Object as PropType<{ [key: string]: string }>,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    csvLoading: {
      type: Boolean,
      default: false
    },
    transactions: {
      type: Array as PropType<TStreamTransaction[]>,
      default: (): TStreamTransaction[] => {
        return [];
      }
    },
    hasNext: {
      type: Boolean,
      default: false
    }
  },
  data(): TCopyStakeTableState {
    return {
      MIN_DATE,
      currentPaginationPage: 1,
      previousPaginationPageDate: [],
      activeOperators: [
        { label: 'Operator name', id: null },
        ...activeOperators
      ],
      selectedOperatorId: null,
      selectedTransactionsType: null,
      selectedSearchKey: ESearchKey.GAME_NAME,
      searchValue: null,
      searchKeys: Object.freeze([
        { text: 'Game name', value: ESearchKey.GAME_NAME },
        { text: 'User ID', value: ESearchKey.USER_ID },
        {
          text: 'Wallet ID Streamer',
          value: ESearchKey.COPIED_FROM_WALLET_HASH
        }
      ]),
      tableHeaders: Object.freeze([
        {
          text: 'Created at',
          value: 'createdAt'
        },
        {
          text: 'Operator',
          value: 'operatorName'
        },
        {
          text: 'Transaction type',
          value: 'type'
        },
        {
          text: `User id`,
          value: 'operatorUserId'
        },
        {
          text: `Amount`,
          value: 'amountInCurrency'
        },
        {
          text: 'Transaction id',
          value: 'id'
        },
        { text: '', value: 'data-table-expand' }
      ])
    };
  },
  computed: {
    paginationLength(): number {
      if (!this.transactions.length) return 0;

      return this.currentPaginationPage + (this.hasNext ? 1 : 0);
    },
    formattedList(): any[] {
      return this.transactions.map((item: TStreamTransaction) => ({
        ...item,
        operatorUserId: item?.attributes?.OPERATOR_USER_ID,
        streamerWallet: item?.attributes?.COPIED_FROM_WALLET,
        amountInUSDT: this.formatCurrency(
          item?.attributes?.AMOUNT_USDT,
          BASE_CURRENCY
        ),
        amountInCurrency: this.formatCurrency(
          item?.attributes?.AMOUNT,
          item?.attributes?.CURRENCY
        ),
        createdAt: this.formatTableDate(item?.createdAt),
        referenceId: item?.attributes?.REFERENCE_GA_ID
      }));
    }
  },
  watch: {
    dates: {
      handler: 'handlerOnDateChange',
      deep: true
    }
  },
  created(): void {
    this.$emit('filter-change', {
      [this.selectedSearchKey]: this.searchValue,
      from: this.formatFilterDate(this.dates.from),
      to: this.formatFilterDate(
        this.previousPaginationPageDate[this.currentPaginationPage] ||
          this.dates.to
      ),
      type: this.selectedTransactionsType
    });
  },
  methods: {
    handlerOnCurrentPageChange(page: number): void {
      const listLength = this.transactions?.length;
      const isFirstPage = page === 1;
      if (isFirstPage) {
        this.previousPaginationPageDate = [];
      }

      const lastPaginationPageDate = isFirstPage
        ? this.formatFilterDate(this.dates.to)
        : this.previousPaginationPageDate[page] ||
          this.transactions[listLength - 1].createdAt;

      this.$emit('filter-change', {
        from: this.formatFilterDate(this.dates.from),
        to: lastPaginationPageDate
      });

      this.currentPaginationPage = page;
      this.previousPaginationPageDate[page] = lastPaginationPageDate;
    },
    resetPaginationParams(): void {
      this.currentPaginationPage = 1;
      this.previousPaginationPageDate = [];
      this.previousPaginationPageDate[this.currentPaginationPage] =
        this.formatFilterDate(this.dates.to);
    },
    resetSearchParams(): void {
      this.resetPaginationParams();
      this.searchValue = null;
      this.$emit('filter-change', {
        [this.selectedSearchKey]: this.searchValue
      });
    },
    handlerOnQueryChange(payload: string): void {
      if (!payload) {
        this.resetSearchParams();
      }
    },
    handlerOnSubmitQuery(): void {
      if (
        this.selectedSearchKey === ESearchKey.USER_ID &&
        !this.selectedOperatorId
      ) {
        this.$toast.info(
          'Before that, you have to choose the operator name.'
        );
        this.$refs['operatorNameSelect']?.activateMenu();
        return;
      }

      this.resetPaginationParams();
      this.$emit('filter-change', {
        [this.selectedSearchKey]: this.searchValue
      });
    },
    handlerOnDateChange(): void {
      this.resetPaginationParams();
      this.$emit('filter-change', {
        from: this.formatFilterDate(this.dates.from),
        to: this.formatFilterDate(
          this.previousPaginationPageDate[this.currentPaginationPage] ||
            this.dates.to
        )
      });
    },
    handlerOnFilterTypeChange(payload: EStreamerTransactionType): void {
      this.resetPaginationParams();
      this.selectedTransactionsType = payload;
      this.$emit('filter-change', { type: this.selectedTransactionsType });
    },
    handlerOnOperatorChange(payload: number): void {
      this.resetPaginationParams();
      this.$emit('filter-change', { operatorId: payload });
    },
    handlerOnDownloadCSV(): void {
      this.$emit('download-csv');
    },
    formatFilterDate(date: string): string {
      if (date?.includes('T')) return date;

      return `${date}T00:00:00.000.000000000`;
    },
    formatCurrency(v: string | number, currency: string = ''): string {
      if (v === VALUE_PLACEHOLDER || (!v && typeof v !== 'number'))
        return VALUE_PLACEHOLDER;

      try {
        return `${this.$options.filters.numeralSpaces(
          v,
          '0,0.[00]'
        )} ${currency}`.trim();
      } catch {
        return VALUE_PLACEHOLDER;
      }
    },
    formatTableDate(createdAt: string): string {
      return formatDate(
        createdAt.slice(0, createdAt.lastIndexOf('.')),
        'MM.dd.yy HH:mm',
        true,
        false
      );
    },
    textOverflowMiddle,
    copyToClipBoard,
    capitalization
  }
};
